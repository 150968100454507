import Home from "./page/index";


function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
