import React, { Component } from 'react'
import { Container, Image, Row, Col, Form,Button } from "react-bootstrap";
import { Carousel } from 'react-responsive-carousel';
import { Link, animateScroll as scroll } from "react-scroll";
import InputMask from "react-input-mask";
import "./style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { ToastContainer ,toast } from "react-toastify";
import ScrollAnimation from 'react-animate-on-scroll';
import AnimatedNumber from "react-animated-numbers"

import { Icon } from '@iconify/react';
import menuico from '@iconify/icons-ri/menu-4-fill';
import { stack as Menu } from 'react-burger-menu'


const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    interval: 10000,
    transitionTime: 150,
});

export default class index extends Component {
    constructor() {
        super();
            this.listener = null;
            this.state = {
                abremenu: false,
                nome:'',
                mail:'',
                cel:'',
                horario:'',
                nomectt:'',
                mailctt:'',
                assuntoctt:'',
                corpoctt:'',
                emailfeed:''

            };
        this.handleClick = this.handleClick.bind(this);
    }

    toggleMenu () {
        this.setState(state => ({abremenu: !state.abremenu}))
    }
    handleClick(valor) {
        if(valor === 'viraSites'){
            this.setState(prevState => ({ viraSites: !prevState.viraSites }));
        }
        
        if(valor === 'viraApp'){
            this.setState(prevState => ({ viraApp: !prevState.viraApp }));
        }
        
        if(valor === 'viraLoja'){
            this.setState(prevState => ({ viraLoja: !prevState.viraLoja }));
        }
        
        if(valor === 'viraSoft'){
            this.setState(prevState => ({ viraSoft: !prevState.viraSoft }));
        }
        
    }
    handleStateChange (state) {
        this.setState({abremenu: state.abremenu})  
    }
    stylesMenu = {
        bmCrossButton: {
          height: '24px',
          width: '24px'
        },
        bmCross: {
          background: '#bdc3c7'
        },
        bmMenuWrap: {
          position: 'fixed',
          height: '100%'
        },
        bmMenu: {
          background: '#04202e',
          padding: '2.5em 1.5em 0',
          fontSize: '1.15em',
          zIndex:9999
        },
        bmMorphShape: {
          fill: '#04202e',
        },
        bmItemList: {
          color: '#b8b7ad',
          padding: '1px',
          width:150
        },
        bmItem: {
          marginTop:'15px'
        },
        bmOverlay: {
          background: 'rgba(0, 0, 0, 0.3)',
          zIndex:999999,
        },
        
    }
    linkWhats = ()=>{
        //window.open('https://api.whatsapp.com/send?phone=5511998122854&text=%20Quero%20or%C3%A7ar%20um%20seguro%20para%20meu%20ve%C3%ADculo','_blank')
        window.open('http://bit.ly/CotacaodeSeguros','_blank')
    }
    ligaTelefone = ()=>{
        window.open('tel:+551155559462','_self')
    }
    linkEmail = ()=>{
        window.open('mailto:adm@ilimitseguros.com.br?subject=Contato direto site Ilimiti Seguros','_blank')
    }
    linkFace = ()=>{
        window.open('https://www.facebook.com/Ilimitcotacaodeseguros','_blank')
    }
    linkInsta = ()=>{
        window.open('https://www.instagram.com/ilimitseguros/','_blank')
    }
    linkLinkedin = ()=>{
        window.open('https://www.linkedin.com/company/ilimit-seguros/','_blank')
    }
    vaiPagina = ()=>{
        window.open('https://www.instagram.com/agenciaalldigitalmarketing/','_blank');
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    validaTelefone = (phone)=>{
        phone = phone.replace("(","").replace(")","").replace("-","").replace(" ","")
        var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'); 
        console.log(regex.test(phone));
        return regex.test(phone);
    }
    async onSubmit(e) {
        const dados = { 
            nome: this.state.nome,
            email: this.state.mail,
            cel: this.state.cel,
            horario: this.state.horario
        }

        var valida = true;

        if(this.state.nome == ""){
            toast.error('🥺 Campo nome não pode ser vazio', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            valida =false;
        }

        if(this.state.email == ""){
            toast.error('🥺 Campo E-mail é inválido', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            valida =false;
        }

        if(this.state.cel == ""){
            toast.error('🥺 Campo Celular não pode ser vazio', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            valida =false;
        }

        if(this.state.horario == ""){
            toast.error('🥺 Selecione um horário', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            valida =false;
        }

        if(!this.validaTelefone(this.state.cel)){
            toast.error('🥺 Celular informador é inválido', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            valida =false;
        }

        if(this.state.horario == "Melhor horário para contato"){
            dados.horario = "Das 9hrs as 12hrs"
        }


        if(valida){
            await axios.post('./email.php', dados).then(
                response => {
                    this.setState({
                        nome:'',
                        mail:'',
                        cel:'',
                        horario:''
                    })
                    alert('Obrigado, nossa equipe entrará em contato com você.')
                    toast.success('😁 Solicitação enviada !', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
    
            ).catch(error => {
                toast.error('🥺 Erro ao enviar, veja se preencheu todos os campos obrigatórios!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.error('aqui', error);
            });
        }

        
    }
    async onSubmitcontato(e) {
        const dados = { 
            nome: this.state.nomectt,
            email: this.state.mailctt,
            assunto: this.state.assuntoctt,
            corpo: this.state.corpoctt
        }

        var valida = true;

        if(this.state.nomectt == ""){
            toast.error('🥺 Campo nome não pode ser vazio', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            valida =false;
        }

        if(this.state.mailctt == ""){
            toast.error('🥺 Campo E-mail é inválido', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            valida =false;
        }

        if(this.state.assuntoctt == ""){
            toast.error('🥺 Campo Assunto não pode ser vazio', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            
            valida =false;
        }

       
        if(valida){
            await axios.post('./emailctt.php', dados).then(
                response => {
                    this.setState({
                        nomectt:'',
                        mailctt:'',
                        assuntoctt:'',
                        corpoctt:''
                    })
    
                    toast.success('😁 Solicitação enviada !', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
    
            ).catch(error => {
                toast.error('🥺 Erro ao enviar, veja se preencheu todos os campos obrigatórios!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.error('aqui', error);
            });
        }

        
    }

    async onSubmitfeed(e) {
        const dados = { 
            email: this.state.emailfeed,
        }

        var valida = true;

        if(this.state.emailfeed == ""){
            toast.error('🥺 Campo e-mail não pode ser vazio', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            valida =false;
        }


       
        if(valida){
            await axios.post('./emailfeed.php', dados).then(
                response => {
                    this.setState({
                        emailfeed:''
                    })
    
                    toast.success('😁 Solicitação enviada !', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
    
            ).catch(error => {
                toast.error('🥺 Erro ao enviar, veja se preencheu todos os campos obrigatórios!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.error('aqui', error);
            });
        }

        
    }

    formatValue = (value) => value.toFixed(2);

    render() {
        
        return (
            <div id="home">
                <ToastContainer/>
                
                <Menu right customBurgerIcon={ false } styles={ this.stylesMenu } className="menu" width={ 200 } isOpen={this.state.abremenu} onStateChange={(state) => this.handleStateChange(state)}>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                    >Home</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="quem"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                    >Quem Somos</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="sa"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    >Seguro Auto</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="sm"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    >Seguro Moto</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="cont"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    >Contato</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="cot"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    >Cotação</Link>
                </Menu>
                
                {/* topo */}
                <div   style={{position:'fixed',width:'100%',zIndex:999999,top:0,backgroundColor:'#0078BD'}}>
                    <div className="topo escodeMobile"  >
                        <div>
                            <img src="./img/logo_imilit.png" style={{height:50,marginLeft:100}}/>
                        </div>
                        <div className="ilimitmenu">
                            <Link
                                className=""
                                activeClass=""
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Home</Link>
                            <Link
                                className=""
                                activeClass=""
                                to="quem"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Quem Somos</Link>
                            <Link
                                className=""
                                activeClass=""
                                to="sa"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Seguro Auto</Link>
                            <Link
                                className=""
                                activeClass=""
                                to="sm"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Seguro Moto</Link>
                            <Link
                                className=""
                                activeClass=""
                                to="cont"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Contato</Link>
                            <Link
                                className=""
                                activeClass=""
                                to="cot"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Cotação</Link>
                        </div>
                        <div style={{marginRight:100,fontWeight:'bold',cursor:'pointer'}} onClick={()=>this.linkWhats()}>
                            <img src="./img/icones/whatstopo.png" style={{width:35,marginRight:5}}/>   
                            (11) 99812-2854
                        </div>
                    </div>
                    <div className="d-md-none d-lg-none d-xl-none menuMobile"  >
                        <Image src="./img/logo_imilit.png" fluid style={{width:110}} onClick={()=>scroll.scrollToTop()}/>
                        <div><Icon icon={menuico} onClick={()=>{this.toggleMenu()}}/></div>
                        
                    </div>
                </div>
                {/* topo */}

                {/* slide */}
                <div  style={{marginTop:50}} className="bannersmobile">
                    <Carousel autoPlay {...getConfigurableProps()}>
                        <div onClick={()=>this.linkWhats()} style={{cursor:"pointer"}}>
                            <img alt="" src="./img/banners/banner2.png" />
                        </div>
                        <div onClick={()=>this.linkWhats()} style={{cursor:"pointer"}}>
                            <img alt="" src="./img/banners/banner3.png"/>
                        </div>
                        <div onClick={()=>this.linkWhats()} style={{cursor:"pointer"}}>
                            <img alt="" src="./img/banners/banner4.png"/>
                        </div>
                        <div onClick={()=>this.linkWhats()} style={{cursor:"pointer"}}>
                            <img alt="" src="./img/banners/banner5.png"/>
                        </div>
                    </Carousel>
                </div>
                {/* slide */}

                {/* contador */}
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div className="boxContador">
                        <div className="blocos">
                            <div className='blocounico' style={{borderRight:'1px solid #fff'}}>
                                <div>
                                    <div style={{fontSize:28,fontWeight:500,display:'flex',marginBottom:-20}}>
                                    <AnimatedNumber
                                    animateToNumber={568}
                                    config={{ tension: 89, friction: 40 }}
                                    animationType={"calm"}
                                    /><span>+</span></div>
                                    <br/>
                                    <div style={{fontSize:12,fontWeight:'bold'}}>Clientes atendidos</div>
                                </div>
                            </div>
                            <div className='blocounico' style={{borderRight:'1px solid #fff'}}>
                                <div>
                                    <div style={{fontSize:28,fontWeight:500,display:'flex',marginBottom:-20}}>
                                    <AnimatedNumber
                                    animateToNumber={1548}
                                    config={{ tension: 89, friction: 40 }}
                                    animationType={"calm"}
                                    /><span>+</span></div>
                                    <br/>
                                    <span style={{fontSize:12,fontWeight:'bold'}}>Sinistros pagos</span>
                                </div>
                            </div>
                            <div className='blocounico' style={{borderRight:'1px solid #fff'}}>
                                <div>
                                    <div style={{fontSize:28,fontWeight:500,display:'flex',marginBottom:-20}}>
                                    <AnimatedNumber
                                    animateToNumber={3259}
                                    config={{ tension: 89, friction: 40 }}
                                    animationType={"calm"}
                                    /><span>+</span></div>
                                    <br/>
                                    <span style={{fontSize:12,fontWeight:'bold'}}>Cotações feitas</span>
                                </div>
                            </div>
                            <div className='blocounico'>
                                <div>
                                    <div style={{fontSize:28,fontWeight:500,display:'flex',marginBottom:-20}}>
                                    <AnimatedNumber
                                    animateToNumber={725}
                                    config={{ tension: 89, friction: 40 }}
                                    animationType={"calm"}
                                    /><span>+</span></div>
                                    <br/>
                                    <span style={{fontSize:12,fontWeight:'bold'}}>Assistencias pro dia</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* contador */}

                {/* vetores */}
                <ScrollAnimation animateIn='fadeInUp'>
                <div style={{display:'flex',justifyContent:'center',marginTop:25,flexWrap:'wrap'}}>
                    <div>
                        <Image src="./img/icones/roubo.png" fluid  style={{height:100,marginLeft:25,marginRight:25}}/>
                    </div>
                    <div>
                        <Image src="./img/icones/furto.png" fluid  style={{height:100,marginLeft:25,marginRight:25}}/>
                    </div>
                    <div>
                        <Image src="./img/icones/colisao.png" fluid  style={{height:100,marginLeft:25,marginRight:25}}/>
                    </div>
                    <div>
                        <Image src="./img/icones/incendio.png" fluid  style={{height:100,marginLeft:25,marginRight:25}}/>
                    </div>
                    <div>
                        <Image src="./img/icones/guincho.png" fluid  style={{height:100,marginLeft:25,marginRight:25}}/>
                    </div>
                </div>
                </ScrollAnimation>
                {/* vetores */}
               
                {/* cotação */}
                <Container id ="cot" style={{marginTop:100}}>
                    <Row>
                        <Col md={6}>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <div>
                                    <div style={{marginTop:80}}>
                                        <Image src="./img/banners/cotacao.png" fluid width={350}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <div>
                                    <div className="titulocontato" style={{marginTop:10}}>Proteja agora o seu veículo</div>
                                    <div className="subtitulocontato" style={{fontSize:18,textAlign:'right'}}>Faça sua cotação Gratuita</div>
                                    <Form style={{marginTop:15}}>
                                        <Form.Control type="text" placeholder="Nome Completo" className="estiloinput" name="nome" onChange={(e)=>this.onChange(e)}/>
                                        <Form.Control type="e-mail" placeholder="E-mail" required  className="estiloinput" name="mail" onChange={(e)=>this.onChange(e)}/>
                                        <InputMask mask="(99) 99999-9999" type="text" placeholder="Celular (99) 99999-9999" required   className="estiloinput celular"  name="cel" onChange={(e)=>this.onChange(e)} />
                                        <Form.Control as="select" className="estiloinput" name="horario" onChange={(e)=>this.onChange(e)}>
                                            <option>Melhor horário para contato</option>
                                            <option>Das 9hrs as 12hrs</option>
                                            <option>Das 13hrs as 15hrs</option>
                                            <option>Das 16hrs as 18hrs</option>
                                        </Form.Control>
                                        <Button variant="primary btn_envia" style={{width:'100%'}} onClick={()=>this.onSubmit()}>Receber Cotação</Button>
                                        <Button variant="primary btn_envia" style={{width:'100%'}} onClick={()=>this.linkWhats()}>
                                            <div>
                                                <Image src="./img/icones/whatsappbtn.png" style={{width:25,marginRight:5}} />
                                                <span>Cotação Rápida via WhatsApp</span>
                                            </div>
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* cotação */}

                {/* seguro auto */}
                <div id ="sa" style={{display:'flex',justifyContent:'center',marginTop:100}}>
                    <div>
                        <div className="tituloseguro">Seguro Auto</div>
                        <div className="subtituloseguro">
                            Encontre as melhores ofertas de seguro de carro por um preço justo. Faça uma cotação de<br/>
                            seguro auto gratuita é 100% online
                        </div>

                        <div style={{display:'flex',justifyContent:'center',marginTop:50,flexWrap:'wrap'}}>
                            <ScrollAnimation animateIn='fadeInUp'>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'space-between',marginTop:10,alignItems:'center'}}>
                                    <div><Image src="./img/icones/et_trophy.png" /></div>
                                    <div><Image src="./img/icones/setas.png" /></div>
                                </div>
                                <div style={{fontSize:20,fontWeight:'bold',color:'#fff',marginTop:10}}>
                                    Lider em Recuperação
                                </div>
                                <div style={{fontSize:10,color:"#fff", marginTop:10}}>
                                    São mais de 2 milhões de clientes no mundo, com mais de 700 mil
                                    deles apenas aqui no Brasil. Esse número cresce a cada ano, fator
                                    que consolida a liderança da Ituran em seu segmento.
                                </div>
                            </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp'>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'space-between',marginTop:10,alignItems:'center'}}>
                                    <div><Image src="./img/icones/12X.png" /></div>
                                    <div><Image src="./img/icones/setas.png" /></div>
                                </div>
                                <div style={{fontSize:20,fontWeight:'bold',color:'#fff',marginTop:25}}>
                                    Parcelas<br/> sem Juros
                                </div>
                                <div style={{fontSize:10,color:"#fff", marginTop:10}}>
                                    Na Ituran você paga seu seguro em até 12X sem juros, no boleto ou cartão.
                                </div>
                            </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp'>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'space-between',marginTop:10,alignItems:'center'}}>
                                    <div><Image src="./img/icones/fa-solid_car-crash.png" /></div>
                                    <div><Image src="./img/icones/setas.png" /></div>
                                </div>
                                <div style={{fontSize:20,fontWeight:'bold',color:'#fff',marginTop:10}}>
                                    Cobertura<br/>Adicional
                                </div>
                                <div style={{fontSize:10,color:"#fff", marginTop:10}}>
                                    Na ituran você tem o seguro mais completo do
                                    mercado, coberturas adicionais para vidros, Fenômenos da natureza e
                                    residencial.
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                {/* seguro auto */}

                {/* banner auto */}
                <Image src="./img/banners/BannerAuto.png" fluid style={{marginTop:100,cursor:'pointer'}} onClick={()=>this.linkWhats()}/>
                {/* banner auto */}


                {/* seguro moto */}
                <div id ="sm" style={{display:'flex',justifyContent:'center',marginTop:100}}>
                    <div>
                        <div className="tituloseguro">Seguro Moto</div>
                        <div className="subtituloseguro">
                            Seguro para moto com assistência. Economize tempo e dinheiro no seguro da sua motocicleta.<br/>
                            Com garantia das principais seguradoras do mercado
                        </div>
                        <ScrollAnimation animateIn='fadeInDown'>
                        <div style={{display:'flex',justifyContent:'center',marginTop:50,flexWrap:'wrap'}}>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/estrela.png"  style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Especialista
                                </div>
                            </div>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/susep.png" style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Regulamentação<br/>
                                    SUSEP
                                </div>
                            </div>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/custo.png" style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Excelente custo<br/> Benefíco
                                </div>
                            </div>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/telefone.png"  style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Excelência no<br/> atendimento<br/> ao cliente
                                </div>
                            </div>
                        </div>
                        </ScrollAnimation>
                        <div className="separador"></div>
                        <ScrollAnimation animateIn='fadeInUp'>
                        <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/ph_users.png"  style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Perfil simplificado<br/> e sem burocracia
                                </div>
                            </div>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/radix-icons_cross-circled.png" style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Sem Franquia
                                </div>
                            </div>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/ri_motorbike-line.png" style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Aceitação
                                </div>
                            </div>
                            <div className="cartao">
                                <div style={{display:'flex',justifyContent:'center',marginTop:30,alignItems:'center'}}>
                                    <div><Image src="./img/icones/cil_history.png"  style={{width:80}}/></div>
                                </div>
                                <div style={{fontSize:15,fontWeight:'bold',color:'#fff',marginTop:20,textAlign:'center'}}>
                                    Assistência 24hrs
                                </div>
                            </div>
                        </div>
                        </ScrollAnimation>
                    </div>
                </div>
                {/* seguro moto */}


                {/* planos */}
                <Container>
                    <Row style={{marginTop:50}}>
                        <Col>
                            <div style={{textAlign:'center',fontSize:36,fontWeight:500,color:'#fff'}}>Planos</div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:50}}>
                    <Col>
                        <div className="cardTotal">
                            <ScrollAnimation animateIn='bounceInLeft' className="cardPlanoesq">
                                <div >
                                    <div>
                                        A partir de
                                    </div>
                                    <div style={{fontSize:40, fontWeight:600,marginTop:-10, color:'#04202e'}}>
                                        R$54,90<span style={{fontSize:16,color:'#fff'}}>/mês</span>
                                    </div>
                                    <div style={{border:'1px solid #fff',width:'50%',margin:'auto',marginTop:10,marginBottom:15}}></div>
                                    <div style={{textAlign:'center',fontSize:20, fontWeight:200,marginBottom:25}}>Rastreador Ituran</div>
                                    <div>
                                        <ul style={{listStyle:'none', fontSize:15,padding:0,fontWeight:200}}>
                                            <li className="check">Chaveiro, guincho, pneus</li>
                                            <li className="check">Rastreador Ituran</li>
                                            <li className="check">Monitoramento Mobile</li>
                                            <li className="check">Assistência 24h</li>
                                            <li className="less">Cobertura para terceiros</li>
                                            <li className="less">Perda Total por colisão</li>
                                            <li className="less">Indenização de até 100%</li>
                                            <li className="less">Cobertura de roubo/furto/incêndio</li>
                                        </ul>
                                    </div>
                                    <div className="instalacao">
                                        *INSTALAÇÃO GRÁTIS
                                    </div>
                                    <div className="whatsCard" onClick={()=>this.linkWhats()}>
                                        <img src="./img/icones/whatsappbtn.png" style={{width:35}}/>
                                        <div style={{fontSize:15, marginLeft:5}}>Receba seu orçamento via<br/> Whatsapp</div>
                                    </div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn='bounceInUp' className="cardPlanomeio">
                                <div >
                                    <div>
                                        A partir de
                                    </div>
                                    <div style={{fontSize:40, fontWeight:600,marginTop:-10, color:'#0286b9'}}>
                                        R$69,90<span style={{fontSize:16,color:'#fff'}}>/mês</span>
                                    </div>
                                    <div style={{border:'1px solid #fff',width:'50%',margin:'auto',marginTop:10,marginBottom:15}}></div>
                                    <div style={{textAlign:'center',fontSize:20, fontWeight:200,marginBottom:25}}>Ituran com seguro</div>
                                    <div>
                                        <ul style={{listStyle:'none', fontSize:15,padding:0,fontWeight:200}}>
                                            <li className="check">Chaveiro, guincho, pneus</li>
                                            <li className="check">Rastreador Ituran</li>
                                            <li className="check">Monitoramento Mobile</li>
                                            <li className="check">Assistência 24h</li>
                                            <li className="check">Cobertura para terceiros</li>
                                            <li className="check">Perda Total por colisão</li>
                                            <li className="less">Indenização de até 100%</li>
                                            <li className="less">Cobertura de roubo/furto/incêndio</li>
                                        </ul>
                                    </div>
                                    <div className="instalacao">
                                        *INSTALAÇÃO GRÁTIS
                                    </div>
                                    <div className="whatsCard" onClick={()=>this.linkWhats()}>
                                        <img src="./img/icones/whats2.png" style={{width:35}}/>
                                        <div style={{fontSize:15, marginLeft:5}}>Receba seu orçamento via<br/> Whatsapp</div>
                                    </div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn='bounceInRight' className="cardPlanodir">
                                <div >
                                    <div>
                                        A partir de
                                    </div>
                                    <div style={{fontSize:40, fontWeight:600,marginTop:-10, color:'#04202e'}}>
                                        R$114,90<span style={{fontSize:16,color:'#fff'}}>/mês</span>
                                    </div>
                                    <div style={{border:'1px solid #fff',width:'50%',margin:'auto',marginTop:10,marginBottom:15}}></div>
                                    <div style={{textAlign:'center',fontSize:20, fontWeight:200,marginBottom:20}}>Rastreador<br/>Ituran Plus</div>
                                    <div>
                                        <ul style={{listStyle:'none', fontSize:15,padding:0,fontWeight:200}}>
                                            <li className="check">Chaveiro, guincho, pneus</li>
                                            <li className="check">Rastreador Ituran</li>
                                            <li className="check">Monitoramento Mobile</li>
                                            <li className="check">Assistência 24h</li>
                                            <li className="check">Cobertura para terceiros</li>
                                            <li className="check">Perda Total por colisão</li>
                                            <li className="check">Indenização de até 100%</li>
                                            <li className="check">Cobertura de roubo/furto/incêndio</li>
                                        </ul>
                                    </div>
                                    <div className="instalacao">
                                        *INSTALAÇÃO GRÁTIS
                                    </div>
                                    <div className="whatsCard" onClick={()=>this.linkWhats()}>
                                        <img src="./img/icones/whatsappbtn.png" style={{width:35}}/>
                                        <div style={{fontSize:15, marginLeft:5}}>Receba seu orçamento via<br/> Whatsapp</div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </Col>
                </Row>
                </Container>
                {/* planos */}

                {/* Contato */}
                <Container id ="cont" style={{marginTop:100}}>
                    <Row>
                        <Col md={5}>
                            <ScrollAnimation animateIn='fadeInLeft'>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <div>
                                    <div className="titulocontato">Contato</div>
                                    <div className="subtitulocontato">Na ILIMIT  Seguros você é atendido por especialistas de verdade, que entendem suas necessidades e buscam  seguros com as coberturas que você necessita e com um preço que cabe no seu bolso. Deixe sue contato, nós iremos te ajudar </div>
                                    <Form style={{marginTop:15}}>
                                        <Form.Control type="text" placeholder="Nome Completo" className="estiloinput" name="nomectt" onChange={(e)=>this.onChange(e)}/>
                                        <Form.Control type="e-mail" placeholder="E-mail" required  className="estiloinput" name="mailctt" onChange={(e)=>this.onChange(e)}/>
                                        <Form.Control type="text" placeholder="Assunto" required  className="estiloinput" name="assuntoctt" onChange={(e)=>this.onChange(e)}/>
                                        <Form.Control as="textarea" name="corpoctt" rows={3} className="estiloinput"  onChange={(e)=>this.onChange(e)} />
                                        <Button variant="primary btn_envia" onClick={()=>this.onSubmitcontato()}>Enviar</Button>
                                    </Form>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </Col>
                        <Col md={7}>
                            <ScrollAnimation animateIn='fadeInRight'>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <div>
                                    <div style={{marginTop:50}}>
                                        <Image src="./img/banners/contato.png" fluid/>
                                    </div>
                                    <div style={{fontSize:15,fontWeight:'normal',color:'#fff'}}>
                                        Faça sua cotação de seguro Auto On-line agora com as melhores seguradoras do Mercado
                                    </div>
                                    <Button variant="primary btn_envia" style={{width:'100%'}} onClick={()=>this.linkWhats()}>
                                        <div>
                                            <Image src="./img/icones/whatsappbtn.png" style={{width:25,marginRight:5}} />
                                            <span>Cotação Rápida via WhatsApp</span>

                                        </div>
                                    </Button>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
                {/* Contato */}

                {/* marcas */}
                <ScrollAnimation animateIn='fadeInUp'>
                <Container style={{marginTop:100}}>
                    <Row style={{marginTop:10}} className="justify-content-center">
                        <Col md={2}>
                            <div className="cardbranco" >
                                <div className="brands">
                                    <img className="img-fluid"  src="./img/liberty.png"/>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="cardbranco">
                                <div className="brands">
                                    <img className="img-fluid"  src="./img/mapfre.png"/>
                                </div>
                            </div>
                        </Col> 
                        <Col md={2}>
                            <div className="cardbranco">
                                <div className="brands">
                                    <img className="img-fluid" style={{}} src="./img/hdi.png"/>
                                </div>
                            </div>                        
                        </Col>
                        <Col md={2}>
                            <div className="cardbranco">
                                <div className="brands" >
                                    <img className="img-fluid"  src="./img/tokyo.png"/>
                                </div>
                            </div>
                        </Col> 
                        <Col md={2}>                        
                            <div className="cardbranco">
                                <div className="brands">
                                    <img className="img-fluid" style={{}} src="./img/ITURAN-slogan.png"/>
                                </div>
                            </div>                        
                        </Col>                     
                    </Row>
                </Container>
                </ScrollAnimation>
                {/* marcas */}

                
                {/* quem somos */}
                <ScrollAnimation animateIn='fadeInRight'>
                <div id="quem" style={{display:'flex',justifyContent:'center',marginTop:100,flexWrap:'wrap'}} className="quemsomoscontainermobile">
                    
                    <div style={{marginRight:45,alignContent:'center',alignItems:'center',display:'flex',}}>
                        <Image src="./img/logo_imilit.png" fluid style={{width:350}} className="logoquemsomosmobile"/>
                    </div>
                    <div style={{borderRight:'3px solid #fff',height:150,marginTop:180}}></div>
                    <div style={{marginLeft:45}}>
                        <div className="titulo1sobre">Quem Somos</div>
                        <div className="titulo2sobre">Um pouco sobre nos</div>
                        <div className="textosobre">
                            Trabalhamos no mercado de Seguros desde 2001 com a missão de conseguir<br/> segurança e tranquilidade as famílias de nossos clientes.<br/><br/>
                            Nestes 20 anos de história buscamos ganhar a confiança de nossos segurados<br/> buscando sempre melhorar a qualidade de nosso atendimento e prestação de<br/>serviços.<br/><br/>
                            Nascida na cidade de São Paulo, a ILIMIT Corretora é legalmente<br/> constituída e devidamente habilitada junto a SUSEP – Superintendência<br/> de seguros privados, operando no segmento de corretagem de seguros<br/> auto.<br/>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                {/* quem somos */}

                {/* quero cotar */}
                <div  className="separador" style={{marginTop:100,width:'100%',borderWidth:10}}></div>
                <Container >
                    <Row>
                        <Col md={6}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <div>
                                    <div style={{marginTop:50}}>
                                        <span style={{color:"#fff",fontSize:25,fontWeight:700}}>Seu seguro do seu jeito!<br/>Faça um orçamento agora. </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <div>
                                    <div style={{marginTop:50}}>
                                        <Button variant="primary btn_envia" style={{width:'100%'}} onClick={()=>this.linkWhats()}>
                                            <div>
                                                <Image src="./img/icones/whatsappbtn.png" style={{width:25,marginRight:5}} />
                                                <span>Cotação Rápida via WhatsApp</span>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* quero cotar */}

                 




                {/* footer */}
                <div style={{width:"100%", backgroundColor:'#292929',marginTop:50,padding:100}} className="footermobile">
                    <Container>
                        <Row>
                            <Col md={4} style={{marginBottom:25}}>
                                <div style={{color:'#fff',fontWeight:'bold',fontSize:20}}>Onde Estamos</div>
                                <div style={{color:"#FE6600",fontSize:15,fontWeight:'bold',marginTop:25}}>Endereço:</div>
                                <div style={{color:'#fff',fontWeight:'normal',fontSize:12,width:150}}>
                                    Estr. de Itapecerica, 4043 - 1 - Andar - Sala 3 - Vila Maracana, São Paulo - SP, 04757-025
                                </div>
                                <div style={{color:"#FE6600",fontSize:15,fontWeight:'bold',marginTop:25}}>Telefone:</div>
                                <div style={{color:'#fff',fontWeight:'normal',fontSize:12,width:150,cursor:'pointer'}} onClick={()=>this.ligaTelefone()}>
                                    (11) 5555-9462
                                </div>
                                <div style={{color:"#FE6600",fontSize:15,fontWeight:'bold',marginTop:25}}>E-mail:</div>
                                <div style={{color:'#fff',fontWeight:'normal',fontSize:12,width:150,cursor:'pointer'}} onClick={()=>this.linkEmail()}>
                                    adm@ilimitseguros.com.br
                                </div>
                            </Col>
                            <Col md={4} style={{marginBottom:25}}>
                                <div style={{color:'#fff',fontWeight:'bold',fontSize:20}}>Mapa do site</div>
                                <div style={{color:"#fff",fontWeight:500,textTransform:'uppercase',borderBottom:'1px solid #3B3A3A',marginTop:25,width:150,marginBottom:15,paddingBottom:5,cursor:'pointer'}}>
                                <Link
                                className="linkmenu"
                                activeClass=""
                                to="quem"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >quem somos</Link>
                                </div>
                                <div style={{color:"#fff",fontWeight:500,textTransform:'uppercase',borderBottom:'1px solid #3B3A3A',width:150,marginBottom:15,paddingBottom:5,cursor:'pointer'}}>
                                <Link
                                className="linkmenu"
                                activeClass=""
                                to="sa"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >SEGURO AUTO</Link>
                                </div>
                                <div style={{color:"#fff",fontWeight:500,textTransform:'uppercase',borderBottom:'1px solid #3B3A3A',width:150,marginBottom:15,paddingBottom:5,cursor:'pointer'}}>
                                <Link
                                className="linkmenu"
                                activeClass=""
                                to="sm"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >SEGURO MOTO</Link>
                                </div>
                                <div style={{color:"#fff",fontWeight:500,textTransform:'uppercase',borderBottom:'1px solid #3B3A3A',width:150,marginBottom:15,paddingBottom:5,cursor:'pointer'}}>
                                <Link
                                className="linkmenu"
                                activeClass=""
                                to="cont"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >CONTATO</Link>
                                </div>
                                <div style={{color:"#fff",fontWeight:500,textTransform:'uppercase',borderBottom:'1px solid #3B3A3A',width:150,marginBottom:15,paddingBottom:5,cursor:'pointer'}}>
                                <Link
                                className="linkmenu"
                                activeClass=""
                                to="cot"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >COTAÇÃO</Link>
                                </div>
                            </Col>
                            <Col md={4} style={{marginBottom:25}}>
                                <div style={{color:'#fff',fontWeight:'bold',fontSize:20}}>Receba nossas novidades</div>
                                <div style={{marginTop:25,display:'inline-flex'}}>
                                    <input placeholder="Seu E-mail" style={{height:40,borderTopLeftRadius:15,borderBottomLeftRadius:15,width:250}} className="inputfeed" name='emailfeed' onChange={(e)=>this.onChange(e)} />
                                    <div style={{backgroundColor:'#FE6600',borderTopRightRadius:15,borderBottomRightRadius:15,padding:5,cursor:'pointer'}} onClick={()=>this.onSubmitfeed()}>
                                        <Image src="./img/icones/fluent_mail-48-regular.png" width="30"/>                                    
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between',width:200,marginTop:25}}>
                                    <div style={{cursor:'pointer'}} onClick={()=>this.linkFace()}><Image src="./img/icones/facebook.png" width="50"/></div>
                                    <div style={{cursor:'pointer'}} onClick={()=>this.linkInsta()}><Image src="./img/icones/instagram.png" width="50"/></div>
                                    <div  style={{cursor:'pointer'}} onClick={()=>this.linkLinkedin()}><Image src="./img/icones/linkeding.png" width="50"/></div>
                                </div>
                                <div style={{marginTop:15}}>
                                    <div><Image src="./img/banners/pagamentos.png" width="200"/></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div style={{width:"100%", backgroundColor:'#333333'}}>
                    <div style={{display:'flex',justifyContent:'space-around', alignItems:'center',flexWrap:'wrap-reverse'}}>
                        <div style={{textAlign:'left',marginTop:15,marginBottom:15,color:'#fff',fontSize:12}}>Copyright © <span style={{color:'#FE6600'}}>2021</span>  |   Todos os diretos reservador.</div>
                        <div style={{textAlign:'center',marginTop:15,marginBottom:15}}><Image src="./img/logo_imilit.png" width={100} /></div>
                        <div style={{textAlign:'center',marginTop:15,marginBottom:15,color:'#fff',fontSize:12,cursor:'pointer'}} onClick={()=>this.vaiPagina()}>Site desenvolvido por Agência ALL Digital Marketing</div>
                    </div>
                </div>
                {/* footer */}



            </div>



            
        )
    }
}
